import { reportClientError } from '/machinery/reportClientError'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

import { initBallsAnimation } from './features/physics/ballsAnimation'
import { getSteamFromConfig, getSteamToConfig, mmConfig } from '/util/gsapHelpers'
import { images } from '/images'

import styles from './Impact.css'

gsap.registerPlugin(ScrollTrigger, SplitText)

export function Impact() {
  const componentRef = React.useRef()
  const titleRef = React.useRef()
  const worldWrapperRef = React.useRef()
  const hasBeenTriggeredRef = React.useRef(false)
  const prevWindowWidthRef = React.useRef(null)

  const destroyAnimationRef = React.useRef(null)
  const pauseAnimationRef = React.useRef(null)
  const resumeAnimationRef = React.useRef(null)

  React.useEffect(() => {
    let resizeTimeout

    function reset() {
      if (hasBeenTriggeredRef.current) {
        destroyAnimationRef.current()
        hasBeenTriggeredRef.current = false
        setTimeout(startAnimation, 500)
      }
    }

    function handleResize() {
      const { innerWidth } = window
      if (innerWidth === prevWindowWidthRef.current) {
        // when you scroll down and then back up in safari mobile, the address bar
        // appears / disappears, causing a resize event we don't want to react to
        // ignore the resize event when the window width hasn't changed
        return
      }
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(reset, 50)
    }

    window?.addEventListener('resize', handleResize)

    return () => {
      window?.removeEventListener('resize', handleResize)
    }
  }, [])

  useGSAP(() => {
    const mm = gsap.matchMedia()
    mm.add(mmConfig, (ctx) => {
      const { isMobile } = ctx.conditions

      const tlTitle = gsap.timeline({
        scrollTrigger: {
          trigger: titleRef.current,
          start: 'top 80%',
          toggleActions: 'play resume resume reset'
        }
      })

      const title = new SplitText(titleRef.current, { type: 'words' })
      tlTitle.fromTo(
        title.words,
        getSteamFromConfig(isMobile),
        getSteamToConfig({ each: 0.05 })
      )

      // trigger for ball animation
      ScrollTrigger.create({
        trigger: titleRef.current,
        start: 'top 60%',
        onEnter: startAnimation,
        id: 'balls',
      })

      // trigger for starting and pausing the physics engine
      ScrollTrigger.create({
        trigger: componentRef.current,
        onEnter: resumeAnimation,
        onEnterBack: resumeAnimation,
        onLeave: pauseAnimation,
        onLeaveBack: pauseAnimation,
      })
    })
  })

  function pauseAnimation() {
    if (pauseAnimationRef.current) {
      pauseAnimationRef.current()
    }
  }

  function resumeAnimation() {
    if (resumeAnimationRef.current) {
      resumeAnimationRef.current()
    }
  }

  function startAnimation() {
    if (hasBeenTriggeredRef.current) {
      return // If animation has already been triggered, do nothing
    }

    prevWindowWidthRef.current = window.innerWidth

    if (!worldWrapperRef.current) {
      reportClientError(new Error('worldWrapperRef.current is null'))
      return
    }

    const { destroy, pause, resume } = initBallsAnimation(worldWrapperRef.current, images.ballImages)
    destroyAnimationRef.current = destroy
    pauseAnimationRef.current = pause
    resumeAnimationRef.current = resume

    hasBeenTriggeredRef.current = true
  }

  return (
    <div ref={componentRef} className={styles.component}>
      <div className={styles.layout} >
        <h2 className={styles.heading} ref={titleRef}>
          Garneer met slimme, impactvolle samenwerkingen en producten.
        </h2>

        <div className={styles.worldWrapper} ref={worldWrapperRef} />
      </div>

      <div className={styles.svgContainer}>
        <svg className={styles.svgPath} viewBox="0 0 100 100" preserveAspectRatio="none">
          {/* A: rX,rY rotation arc sweep endX,endY */}
          <path className={styles.curve} id="ground" d="M-50,-35 A 100,100 0 0 0 150,-35 L150,100 L0,100 L0,0" />
          <path className={styles.curveLg} id="ground" d="M-15,-35 A 65,100 0 0 0 115,-35 L115,100 L0,100 L0,0" />
        </svg>
      </div>

    </div>
  )
}
